/**
 * This file is automatically generated on build stage
 *
 * should not be edited,
 * run build of the project to update it
 */
import React from 'react';
import loadable from '@loadable/component';

const Root = loadable(() => import('./applications/'));
const Converters = loadable(() => import('./applications/converters/'));
const ConvertersBase64 = loadable(() => import('./applications/converters/base64/'));
const ConvertersIndex = loadable(() => import('./applications/converters/index/'));
const ConvertersPunycode = loadable(() => import('./applications/converters/punycode/'));
const ConvertersUrlencode = loadable(() => import('./applications/converters/urlencode/'));
const Generators = loadable(() => import('./applications/generators/'));
const GeneratorsHash = loadable(() => import('./applications/generators/hash/'));
const GeneratorsHashIndex = loadable(() => import('./applications/generators/hash/index/'));
const GeneratorsHashMd5 = loadable(() => import('./applications/generators/hash/md5/'));
const GeneratorsHashSha1 = loadable(() => import('./applications/generators/hash/sha1/'));
const GeneratorsHashSha256 = loadable(() => import('./applications/generators/hash/sha256/'));
const GeneratorsHashSha384 = loadable(() => import('./applications/generators/hash/sha384/'));
const GeneratorsHashSha512 = loadable(() => import('./applications/generators/hash/sha512/'));
const GeneratorsIndex = loadable(() => import('./applications/generators/index/'));
const GeneratorsPassword = loadable(() => import('./applications/generators/password/'));
const Index = loadable(() => import('./applications/index/'));
const NotFound = loadable(() => import('./NotFound'));


const routes = {
    '/': {
      element: <Root/>,
      title: 'Root',
      children: {
        'converters': {
          element: <Converters/>,
          title: 'Converters',
          children: {
            'base64': {
              element: <ConvertersBase64/>,
              title: 'ConvertersBase64',
              staticPaths: ['text', 'image', '', 'text/encode', 'text/decode', 'image/encode', 'image/decode']
            },
            'index': {
              element: <ConvertersIndex tree={{"base64":{"path":"/converters/base64/","staticPaths":["text","image","","text/encode","text/decode","image/encode","image/decode"],"componentName":"ConvertersBase64"},"punycode":{"path":"/converters/punycode/","staticPaths":["encode","decode"],"componentName":"ConvertersPunycode"},"urlencode":{"path":"/converters/urlencode/","staticPaths":["encode","decode"],"componentName":"ConvertersUrlencode"}}}/>,
              title: 'ConvertersIndex',
              staticPaths: []
            },
            'punycode': {
              element: <ConvertersPunycode/>,
              title: 'ConvertersPunycode',
              staticPaths: ['encode', 'decode']
            },
            'urlencode': {
              element: <ConvertersUrlencode/>,
              title: 'ConvertersUrlencode',
              staticPaths: ['encode', 'decode']
            },
          }
        },
        'generators': {
          element: <Generators/>,
          title: 'Generators',
          children: {
            'hash': {
              element: <GeneratorsHash/>,
              title: 'GeneratorsHash',
              children: {
                'index': {
                  element: <GeneratorsHashIndex tree={{"md5":{"path":"/generators/hash/md5/","staticPaths":[],"componentName":"GeneratorsHashMd5"},"sha1":{"path":"/generators/hash/sha1/","staticPaths":[],"componentName":"GeneratorsHashSha1"},"sha256":{"path":"/generators/hash/sha256/","staticPaths":[],"componentName":"GeneratorsHashSha256"},"sha384":{"path":"/generators/hash/sha384/","staticPaths":[],"componentName":"GeneratorsHashSha384"},"sha512":{"path":"/generators/hash/sha512/","staticPaths":[],"componentName":"GeneratorsHashSha512"}}}/>,
                  title: 'GeneratorsHashIndex',
                  staticPaths: []
                },
                'md5': {
                  element: <GeneratorsHashMd5/>,
                  title: 'GeneratorsHashMd5',
                  staticPaths: []
                },
                'sha1': {
                  element: <GeneratorsHashSha1/>,
                  title: 'GeneratorsHashSha1',
                  staticPaths: []
                },
                'sha256': {
                  element: <GeneratorsHashSha256/>,
                  title: 'GeneratorsHashSha256',
                  staticPaths: []
                },
                'sha384': {
                  element: <GeneratorsHashSha384/>,
                  title: 'GeneratorsHashSha384',
                  staticPaths: []
                },
                'sha512': {
                  element: <GeneratorsHashSha512/>,
                  title: 'GeneratorsHashSha512',
                  staticPaths: []
                },
              }
            },
            'index': {
              element: <GeneratorsIndex tree={{"hash":{"path":"/generators/hash/","children":{"md5":{"path":"/generators/hash/md5/","staticPaths":[],"componentName":"GeneratorsHashMd5"},"sha1":{"path":"/generators/hash/sha1/","staticPaths":[],"componentName":"GeneratorsHashSha1"},"sha256":{"path":"/generators/hash/sha256/","staticPaths":[],"componentName":"GeneratorsHashSha256"},"sha384":{"path":"/generators/hash/sha384/","staticPaths":[],"componentName":"GeneratorsHashSha384"},"sha512":{"path":"/generators/hash/sha512/","staticPaths":[],"componentName":"GeneratorsHashSha512"}},"componentName":"GeneratorsHash"},"password":{"path":"/generators/password/","staticPaths":[],"componentName":"GeneratorsPassword"}}}/>,
              title: 'GeneratorsIndex',
              staticPaths: []
            },
            'password': {
              element: <GeneratorsPassword/>,
              title: 'GeneratorsPassword',
              staticPaths: []
            },
          }
        },
        'index': {
          element: <Index tree={{"converters":{"path":"/converters/","children":{"base64":{"path":"/converters/base64/","staticPaths":["text","image","","text/encode","text/decode","image/encode","image/decode"],"componentName":"ConvertersBase64"},"punycode":{"path":"/converters/punycode/","staticPaths":["encode","decode"],"componentName":"ConvertersPunycode"},"urlencode":{"path":"/converters/urlencode/","staticPaths":["encode","decode"],"componentName":"ConvertersUrlencode"}},"componentName":"Converters"},"generators":{"path":"/generators/","children":{"hash":{"path":"/generators/hash/","children":{"md5":{"path":"/generators/hash/md5/","staticPaths":[],"componentName":"GeneratorsHashMd5"},"sha1":{"path":"/generators/hash/sha1/","staticPaths":[],"componentName":"GeneratorsHashSha1"},"sha256":{"path":"/generators/hash/sha256/","staticPaths":[],"componentName":"GeneratorsHashSha256"},"sha384":{"path":"/generators/hash/sha384/","staticPaths":[],"componentName":"GeneratorsHashSha384"},"sha512":{"path":"/generators/hash/sha512/","staticPaths":[],"componentName":"GeneratorsHashSha512"}},"componentName":"GeneratorsHash"},"password":{"path":"/generators/password/","staticPaths":[],"componentName":"GeneratorsPassword"}},"componentName":"Generators"}}}/>,
          title: 'Index',
          staticPaths: []
        },
      }
    },
    '/:lang': {
      element: <Root/>,
      title: 'Root',
      children: {
        'converters': {
          element: <Converters/>,
          title: 'Converters',
          children: {
            'base64': {
              element: <ConvertersBase64/>,
              title: 'ConvertersBase64',
              staticPaths: ['text', 'image', '', 'text/encode', 'text/decode', 'image/encode', 'image/decode']
            },
            'index': {
              element: <ConvertersIndex tree={{"base64":{"path":"/converters/base64/","staticPaths":["text","image","","text/encode","text/decode","image/encode","image/decode"],"componentName":"ConvertersBase64"},"punycode":{"path":"/converters/punycode/","staticPaths":["encode","decode"],"componentName":"ConvertersPunycode"},"urlencode":{"path":"/converters/urlencode/","staticPaths":["encode","decode"],"componentName":"ConvertersUrlencode"}}}/>,
              title: 'ConvertersIndex',
              staticPaths: []
            },
            'punycode': {
              element: <ConvertersPunycode/>,
              title: 'ConvertersPunycode',
              staticPaths: ['encode', 'decode']
            },
            'urlencode': {
              element: <ConvertersUrlencode/>,
              title: 'ConvertersUrlencode',
              staticPaths: ['encode', 'decode']
            },
          }
        },
        'generators': {
          element: <Generators/>,
          title: 'Generators',
          children: {
            'hash': {
              element: <GeneratorsHash/>,
              title: 'GeneratorsHash',
              children: {
                'index': {
                  element: <GeneratorsHashIndex tree={{"md5":{"path":"/generators/hash/md5/","staticPaths":[],"componentName":"GeneratorsHashMd5"},"sha1":{"path":"/generators/hash/sha1/","staticPaths":[],"componentName":"GeneratorsHashSha1"},"sha256":{"path":"/generators/hash/sha256/","staticPaths":[],"componentName":"GeneratorsHashSha256"},"sha384":{"path":"/generators/hash/sha384/","staticPaths":[],"componentName":"GeneratorsHashSha384"},"sha512":{"path":"/generators/hash/sha512/","staticPaths":[],"componentName":"GeneratorsHashSha512"}}}/>,
                  title: 'GeneratorsHashIndex',
                  staticPaths: []
                },
                'md5': {
                  element: <GeneratorsHashMd5/>,
                  title: 'GeneratorsHashMd5',
                  staticPaths: []
                },
                'sha1': {
                  element: <GeneratorsHashSha1/>,
                  title: 'GeneratorsHashSha1',
                  staticPaths: []
                },
                'sha256': {
                  element: <GeneratorsHashSha256/>,
                  title: 'GeneratorsHashSha256',
                  staticPaths: []
                },
                'sha384': {
                  element: <GeneratorsHashSha384/>,
                  title: 'GeneratorsHashSha384',
                  staticPaths: []
                },
                'sha512': {
                  element: <GeneratorsHashSha512/>,
                  title: 'GeneratorsHashSha512',
                  staticPaths: []
                },
              }
            },
            'index': {
              element: <GeneratorsIndex tree={{"hash":{"path":"/generators/hash/","children":{"md5":{"path":"/generators/hash/md5/","staticPaths":[],"componentName":"GeneratorsHashMd5"},"sha1":{"path":"/generators/hash/sha1/","staticPaths":[],"componentName":"GeneratorsHashSha1"},"sha256":{"path":"/generators/hash/sha256/","staticPaths":[],"componentName":"GeneratorsHashSha256"},"sha384":{"path":"/generators/hash/sha384/","staticPaths":[],"componentName":"GeneratorsHashSha384"},"sha512":{"path":"/generators/hash/sha512/","staticPaths":[],"componentName":"GeneratorsHashSha512"}},"componentName":"GeneratorsHash"},"password":{"path":"/generators/password/","staticPaths":[],"componentName":"GeneratorsPassword"}}}/>,
              title: 'GeneratorsIndex',
              staticPaths: []
            },
            'password': {
              element: <GeneratorsPassword/>,
              title: 'GeneratorsPassword',
              staticPaths: []
            },
          }
        },
        'index': {
          element: <Index tree={{"converters":{"path":"/converters/","children":{"base64":{"path":"/converters/base64/","staticPaths":["text","image","","text/encode","text/decode","image/encode","image/decode"],"componentName":"ConvertersBase64"},"punycode":{"path":"/converters/punycode/","staticPaths":["encode","decode"],"componentName":"ConvertersPunycode"},"urlencode":{"path":"/converters/urlencode/","staticPaths":["encode","decode"],"componentName":"ConvertersUrlencode"}},"componentName":"Converters"},"generators":{"path":"/generators/","children":{"hash":{"path":"/generators/hash/","children":{"md5":{"path":"/generators/hash/md5/","staticPaths":[],"componentName":"GeneratorsHashMd5"},"sha1":{"path":"/generators/hash/sha1/","staticPaths":[],"componentName":"GeneratorsHashSha1"},"sha256":{"path":"/generators/hash/sha256/","staticPaths":[],"componentName":"GeneratorsHashSha256"},"sha384":{"path":"/generators/hash/sha384/","staticPaths":[],"componentName":"GeneratorsHashSha384"},"sha512":{"path":"/generators/hash/sha512/","staticPaths":[],"componentName":"GeneratorsHashSha512"}},"componentName":"GeneratorsHash"},"password":{"path":"/generators/password/","staticPaths":[],"componentName":"GeneratorsPassword"}},"componentName":"Generators"}}}/>,
          title: 'Index',
          staticPaths: []
        },
      }
    },
    '*': {
      element: <NotFound/>,
      title: 'NotFound',
    },

}

export default routes;
