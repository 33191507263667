import React, { FC } from 'react';
import styled from 'styled-components';
import loadable from '@loadable/component';
const Header = loadable(() => import('../Layout/Header'));
// import Header from '../Layout/Header';

const FullWidthWrapper = styled.div`
  width: 100%;
`;

const HeaderWrapper1 = styled.div`
  display: flex;
`;

const HeaderWrapper: FC<IWithChildren> = ({children}) => (
  <HeaderWrapper1>
    <FullWidthWrapper>
      {children}
    </FullWidthWrapper>
  </HeaderWrapper1>
);


const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

const AppWrapper1 = styled.div`
  display: flex;
  flex: 1;
  //background-color: #c7cfd0;
  background-color: ${props => props.theme.themeColors.support['100']};
`;

const AppWrapper2 = styled.div`
  max-width: 1366px;
  margin: 10px auto;
  background: white;
  border-radius: 5px;
  border: solid 1px rgba(0,0,0,.2);
  box-shadow: 3px 3px 8px rgba(0,0,0,.05);
  padding: 20px;
`;

const AppWrapper: FC<IWithChildren> = ({children}) => (
  <AppWrapper1>
    <FullWidthWrapper>
      <AppWrapper2>
        {children}
      </AppWrapper2>
    </FullWidthWrapper>
  </AppWrapper1>
);

const FooterWrapper = styled.div`
  display: flex;
  background-color: bisque;
`;

const AppTemplate: FC<IWithChildren> = ({children}) => {
  return (
    <Wrapper>
      <HeaderWrapper>
        <Header />
      </HeaderWrapper>
      <AppWrapper>
        {children}
      </AppWrapper>
      <FooterWrapper>
        {/*<div>footer</div>*/}
      </FooterWrapper>
    </Wrapper>
  )
}

export default AppTemplate;
