import { normal } from 'color-blend';

export const getHoverColor = (color: string, addedOpacity: number): string => {
  const rgb = hexToRgb(color);
  if (!rgb) {
    return color;
  }

  const black = { r: 0, g: 0, b: 0, a: addedOpacity };
  const mixed = normal({ ...rgb, a: 1 }, black);
  return `rgba(${mixed.r},${mixed.g},${mixed.b}, ${mixed.a.toFixed(2)})`;
};

export const hexToRgb = (hex): null | IRgbColor => {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

export const hexToRgbA = (hex: string, alpha: number): string => {
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split('');
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = '0x' + c.join('');
    // eslint-disable-next-line no-bitwise
    return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')}, ${alpha || 1})`;
  }
  throw new Error('Bad Hex');
}
