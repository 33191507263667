import React, { createContext } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import withStyles from 'isomorphic-style-loader/withStyles';
import 'focus-visible/dist/focus-visible';
import theme from './common/theme';
import routes from './routes'; // run `yarn build` to create the file if absent
// @ts-ignore
import appCss from './App.css';
import AppTemplate from "./common/Components/Templates/AppTemplate";

export const DataContext = createContext(null);

const buildChildren = (routes): any => {
  return Object.keys(routes).map((key, listKey) => {
    const { element, children } = routes[key];
    if (!children) {
      // console.log(`
      // Route
      //   key=${listKey}
      //   ${(key === 'index' ? `index: true` : `path: ${key}/*`)}
      //   element=${element}
      //   `)
      return (
        <Route
          key={listKey}
          {...(key === 'index' ? ({index: true}) : ({path: `${key}/*`}))}
          element={element}
        />
      )
    }
    return (
      <Route key={listKey} path={key} element={element}>
        { buildChildren (children) }
      </Route>
    )
  })
}

const App = ({setData}) => {

  return (
    <>
      <DataContext.Provider
        value={setData}
      >
        <ThemeProvider theme={theme}>
          <AppTemplate>
            <Routes>
              { buildChildren(routes) }
            </Routes>
          </AppTemplate>
        </ThemeProvider>
      </DataContext.Provider>
    </>
  )
};

App.defaultProps = {
  setData: undefined,
}

export default withStyles(appCss)(App);
