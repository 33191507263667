import React from 'react';
import { hydrate } from 'react-dom';
import { createRoot } from 'react-dom/client';
import { loadableReady } from '@loadable/component';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import i18next, { InitOptions } from 'i18next';
import { I18nextProvider, initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import StyleContext from 'isomorphic-style-loader/StyleContext';
import { DEFAULT_LANGUAGE } from './const';
import App from './App';
import {renderToString, renderToStaticMarkup} from "react-dom/server";

const localesPath = '/static/locales';

const i18nOptions = (opts: { defaultLanguage: string; namespaces: string[] }) => {
  const options: InitOptions = {
    fallbackLng: opts.defaultLanguage,
    load: 'all',
    preload: [opts.defaultLanguage],
    ns: opts.namespaces,
    keySeparator: '##### not used #####', // using full text keys
    nsSeparator: false,
    saveMissing: true,
    debug: false,

    interpolation: {
      escapeValue: false,
      formatSeparator: ',',
      format: (value, format): string => {
        if (format === 'uppercase') {
          return value.toUpperCase();
        }
        return value;
      },
    },
  };

  return options;
};

const initI18n = async (opts: {
  defaultLanguage: string;
  namespaces: string[];
  loadPath?: string;
  addPath?: string;
}) => {
  const options: InitOptions = i18nOptions(opts);
  options.backend = {
    loadPath: opts.loadPath,
  };
  options.react = { useSuspense: true };

  const i18n = i18next.createInstance(options);
  i18n
    .use(HttpApi)
    .use(LanguageDetector)
    .use(initReactI18next);

  i18n.setDefaultNamespace('common');

  await i18n.init({
    detection: {
      order: ['htmlTag', 'path']
    }
  });

  // @ts-ignore
  window.i18n = i18n;

  return i18n;
};

const loadNS = (i18n) => {
  return new Promise((resolve, reject) => {
    setInterval (() => {
      if (i18n.store.data.en['base64'] !== undefined) {
        resolve(true);
      }
    }, 20);
  })
};

const asyncInit = async (): Promise<void> => {

  const i18n = await initI18n({
    defaultLanguage: DEFAULT_LANGUAGE,
    namespaces: ['common'],
    loadPath: localesPath + '/{{lng}}/{{ns}}.json',
    addPath: localesPath + '/{{lng}}/{{ns}}.missing.json',
  });

  await loadableReady();

  const insertCss = (...styles) => {
    const removeCss = styles.map(style => style._insertCss());
    return () => removeCss.forEach(dispose => dispose())
  };

  // console.log('1111-=-=-=-=-=');
  // console.log(JSON.stringify(i18n.reportNamespaces));

  // ReactDOM.createRoot(document.createElement('div'))
  // const root = createRoot(document.createElement('div'));
  // root.render(<BrowserRouter>
  //   <HelmetProvider>
  //     <I18nextProvider i18n={i18n}>
  //       <StyleContext.Provider value={{ insertCss }}>
  //         <App />
  //       </StyleContext.Provider>
  //     </I18nextProvider>
  //   </HelmetProvider>
  // </BrowserRouter>)

  // renderToStaticMarkup(<BrowserRouter>
  //   <HelmetProvider>
  //     <I18nextProvider i18n={i18n}>
  //       <StyleContext.Provider value={{ insertCss }}>
  //         <App />
  //       </StyleContext.Provider>
  //     </I18nextProvider>
  //   </HelmetProvider>
  // </BrowserRouter>);
  // console.log('2222-=-=-=-=-=');
  // console.log(JSON.stringify(i18n.reportNamespaces));
  //
  // console.log(i18n.t('test', {ns: 'base64'}));
  // console.log(JSON.stringify(i18n.store.data.en));
  // console.log(JSON.stringify(i18n.store.data.en['base64']));
  //
  // await loadNS(i18n);

  hydrate(
    <BrowserRouter>
      <HelmetProvider>
        <I18nextProvider i18n={i18n}>
          <StyleContext.Provider value={{ insertCss }}>
            <App />
          </StyleContext.Provider>
        </I18nextProvider>
      </HelmetProvider>
    </BrowserRouter>,
    document.getElementById('root')
  );
}

asyncInit();

if (module.hot) {
  module.hot.accept();
}
