export const DEFAULT_LANGUAGE = 'en';
export const OMIT_DEFAULT_LANGUAGE = true;
export const GOOGLE_ANALYTICS_TAG = `<!-- Google tag (gtag.js) -->
<script async src="https://www.googletagmanager.com/gtag/js?id=G-2E8K2T5ZRT"></script>
<script>
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());

  gtag('config', 'G-2E8K2T5ZRT');
</script>`;
export const BASE_URL = 'https://web-craft.org';
