import { getHoverColor, hexToRgbA } from '../../utils'

const theme = () => {

  const baseSpacing = 18;
  const defaultSpacing = 16;
  const minorSpacing = 8;

  const spacing = {
    basic: `${baseSpacing}px`,
    default: `${defaultSpacing}px`,
    // sesqui: `${defaultSpacing * 1.5}px`,
    minor: `${minorSpacing}px`,
  };

  const colors = {
    white: '#ffffff',
    black: '#16181a',
    blue: {
      light: '',
      // regular: '#1276DC',
      regular: '#0092b3',
      dark: '#1d5073',
    },
    green: {
      regular: '#168500',
    },
    gray: {
      light: '#F0F1F2',
      medium: '#C8CACC',
      regular: '#919599',
      dark: '#5E6266',
      hover: '#E5E6E6',
    },
    red: {
      regular: '#DE3510',
    },
    yellow: {
      regular: '#FFC905',
    },
    purple: {
      regular: '#8B28C4',
    },
  };

  const paletteBase = {
    primary: colors.blue.regular,
    secondary: colors.gray.light,
    white: colors.white,
    black: colors.black,
    gray: colors.gray.regular,
    lightGray: colors.gray.medium,
    veryLightGray: colors.gray.light,
    darkGray: colors.gray.dark,
    hoverGray: colors.gray.hover,
    attention: colors.red.regular,
    error: colors.red.regular,
    warning: colors.yellow.regular,
    success: colors.green.regular,
    visitedLink: colors.purple.regular,
  };

  const themeColors = {
    base: {
      100: '#afdce6',
      200: '#69bed1',
      300: '#1f9eba',
      400: '#037d98',
      500: '#035e73',
      600: '#02404e',
    },
    support: {
      100: '#c8d7db',
      200: '#9bb7bd',
      300: '#7197a0',
      400: '#59777e',
      500: '#43595f',
      600: '#2d3c40',
    }
  }
    /*
  --Basecolor100: #afdce6;
  --Basecolor200: #69bed1;
  --Basecolor300: #1f9eba;
  --Basecolor400: #037d98;
  --Basecolor500: #035e73;
  --Basecolor600: #02404e;
  --supportcolors100: #c8d7db;
  --supportcolors200: #9bb7bd;
  --supportcolors300: #7197a0;
  --supportcolors400: #59777e;
  --supportcolors500: #43595f;
  --supportcolors600: #2d3c40;
     */

  const hoverPrimary = getHoverColor(paletteBase?.primary || colors.blue.regular, 0.13);
  const hoverButtonPrimary = getHoverColor(paletteBase?.primary || colors.blue.regular, 0.2);
  const activeButtonPrimary = getHoverColor(paletteBase?.primary || colors.blue.regular, 0.44);
  const hoverButtonSecondary = getHoverColor(paletteBase?.secondary || colors.gray.light, 0.05);
  const activeButtonSecondary = getHoverColor(paletteBase?.secondary || colors.gray.light, 0.1025);

  // const hoverButtonToggle = getHoverColor(paletteBase?.secondary || colors.gray.light, 0.08);
  // const pressedButtonToggle = getHoverColor(paletteBase?.secondary || colors.gray.light, 0.05);
  // const activeButtonToggle = getHoverColor(paletteBase?.secondary || colors.gray.light, 0.1025);

  const palette = {
    hoverPrimary,
    hoverButtonPrimary,
    activeButtonPrimary,
    hoverButtonSecondary,
    activeButtonSecondary,
    ...paletteBase
  };

  const base = {
    font: {
      family: 'Arial, sans-serif',
      weight: 'normal',
      size: 14,
      color: palette.darkGray,
    },
    border: {
      radius: '3px',
      style: `1px solid ${palette.lightGray}`,
    },
    spacing,
    color: {
      background: palette.white,
    },
    lineheight: `${baseSpacing}px`,
    icon: {
      size: '24px',
    },
  };

  const buttons = {
    base: {
      text: {
        color: palette.darkGray,
      },
      border: palette.lightGray,
      color: palette.secondary,
      padding: `${defaultSpacing}px`,
      shadow: '0 4px 5px rgba(66,80,97,0.1)',
    },
    primary: {
      text: {
        color: palette.white,
      },
      color: palette.primary,
      padding: `${defaultSpacing}px`,
    },
    disabled: {
      opacity: 0.4,
    },
  };

  const accessibility = {
    ariaFocusHighlight: `0 0 1px 2px ${colors.blue.regular}`,
    dropDownHighlight: `0 0 1px 2px ${colors.blue.regular} inset`,
    navTabsSelectedHighlight: `0 0 1px 2px ${colors.blue.regular} inset`,
    borderFocusHighlight: `2px solid ${colors.blue.regular}`,
  };

  const forms = {
    font: {
      family: base.font.family,
      weight: base.font.weight,
      color: palette.lightGray,
      size: base.font.size,
    },
    border: {
      style: base.border.style,
      radius: base.border.radius,
    },
    focused: {
      color: palette.primary,
      outline: 0,
      outlineOffset: '2px',
      outlineOffsetInner: '-2px',
    },
    placeholder: {
      color: palette.gray,
    },
    select: {
      items: {
        normal: {
          background: palette.white,
        },
        selected: {
          background: palette.primary,
          color: palette.white,
        },
        focused: {
          background: hexToRgbA(palette.primary, 1),
          color: palette.white,
        },
      },
    },

  };

  return {
    spacing,
    palette,
    base,
    forms,
    accessibility,
    buttons,
    themeColors,
  }
}

export default theme;

/*
https://leonardocolor.io/theme.html?name=Web-Craft&config=%7B%22baseScale%22%3A%22Base+color%22%2C%22colorScales%22%3A%5B%7B%22name%22%3A%22support+colors%22%2C%22colorKeys%22%3A%5B%22%23739aa3%22%5D%2C%22colorspace%22%3A%22RGB%22%2C%22ratios%22%3A%5B%221.47%22%2C%222.12%22%2C%223.15%22%2C%224.81%22%2C%227.34%22%2C%2211.37%22%5D%2C%22smooth%22%3Afalse%7D%2C%7B%22name%22%3A%22Base+color%22%2C%22colorKeys%22%3A%5B%22%230492b2%22%5D%2C%22colorspace%22%3A%22RGB%22%2C%22ratios%22%3A%5B%221.47%22%2C%222.12%22%2C%223.15%22%2C%224.81%22%2C%227.34%22%2C%2211.37%22%5D%2C%22smooth%22%3Afalse%7D%5D%2C%22lightness%22%3A100%2C%22contrast%22%3A1%2C%22saturation%22%3A100%2C%22formula%22%3A%22wcag2%22%7D

let baseColor = new Leo.Color({
  name: "Base color",
  colorKeys: ['#0492b2'],
  ratios: [1.47,2.12,3.15,4.81,7.34,11.37],
  colorspace: "RGB",
  smooth: false
});

let supportColors = new Leo.Color({
  name: "support colors",
  colorKeys: ['#739aa3'],
  ratios: [1.47,2.12,3.15,4.81,7.34,11.37],
  colorspace: "RGB",
  smooth: false
});

let Untitled = new Leo.Theme({
  colors: [baseColor,supportColors],
  backgroundColor: baseColor,
  lightness: 100,
  contrast: 1,
  saturation: 100,
  output: "HEX"
  formula: "wcag2"
});

.Untitled {
  --background: #ffffff;
  --Basecolor100: #afdce6;
  --Basecolor200: #69bed1;
  --Basecolor300: #1f9eba;
  --Basecolor400: #037d98;
  --Basecolor500: #035e73;
  --Basecolor600: #02404e;
  --supportcolors100: #c8d7db;
  --supportcolors200: #9bb7bd;
  --supportcolors300: #7197a0;
  --supportcolors400: #59777e;
  --supportcolors500: #43595f;
  --supportcolors600: #2d3c40;
}

{
  "Untitled": {
    "description": "Color theme tokens at lightness of 100%",
    "Background": {
      "value": "#ffffff",
      "type": "color",
      "description": "UI background color. All color contrasts evaluated and generated against this color."
    },
    "Basecolor100": {
      "value": "#afdce6",
      "type": "color",
      "description": "Do not use for UI elements or text. WCAG 2.x (relative luminance) contrast is 1.47:1 against background #ffffff"
    },
    "Basecolor200": {
      "value": "#69bed1",
      "type": "color",
      "description": "Do not use for UI elements or text. WCAG 2.x (relative luminance) contrast is 2.12:1 against background #ffffff"
    },
    "Basecolor300": {
      "value": "#1f9eba",
      "type": "color",
      "description": "Color can be used for UI elements or large text. WCAG 2.x (relative luminance) contrast is 3.15:1 against background #ffffff"
    },
    "Basecolor400": {
      "value": "#037d98",
      "type": "color",
      "description": "Color can be used for small text. WCAG 2.x (relative luminance) contrast is 4.81:1 against background #ffffff"
    },
    "Basecolor500": {
      "value": "#035e73",
      "type": "color",
      "description": "Color can be used for small text. WCAG 2.x (relative luminance) contrast is 7.34:1 against background #ffffff"
    },
    "Basecolor600": {
      "value": "#02404e",
      "type": "color",
      "description": "Color can be used for small text. WCAG 2.x (relative luminance) contrast is 11.37:1 against background #ffffff"
    },
    "supportcolors100": {
      "value": "#c8d7db",
      "type": "color",
      "description": "Do not use for UI elements or text. WCAG 2.x (relative luminance) contrast is 1.47:1 against background #ffffff"
    },
    "supportcolors200": {
      "value": "#9bb7bd",
      "type": "color",
      "description": "Do not use for UI elements or text. WCAG 2.x (relative luminance) contrast is 2.12:1 against background #ffffff"
    },
    "supportcolors300": {
      "value": "#7197a0",
      "type": "color",
      "description": "Color can be used for UI elements or large text. WCAG 2.x (relative luminance) contrast is 3.15:1 against background #ffffff"
    },
    "supportcolors400": {
      "value": "#59777e",
      "type": "color",
      "description": "Color can be used for small text. WCAG 2.x (relative luminance) contrast is 4.81:1 against background #ffffff"
    },
    "supportcolors500": {
      "value": "#43595f",
      "type": "color",
      "description": "Color can be used for small text. WCAG 2.x (relative luminance) contrast is 7.34:1 against background #ffffff"
    },
    "supportcolors600": {
      "value": "#2d3c40",
      "type": "color",
      "description": "Color can be used for small text. WCAG 2.x (relative luminance) contrast is 11.37:1 against background #ffffff"
    }
  }
}
 */
